import '../styles/main.scss'

import {makeRequest, confirmAlert} from './inc/functions.js';

$(() => {

    // Backgound glow
     $(document).on('mousemove', function(e) {
        let windowWidth = $(window).width();
        let windowHeight = $(window).height();

        let mouseXpercentage = Math.round(e.pageX / windowWidth * 100);
        let mouseYpercentage = Math.round(e.pageY / windowHeight * 100);

        $('body').css('background', 'radial-gradient(at ' + mouseXpercentage + '% ' + mouseYpercentage + '%, #fff, #d4e1fa)');
    });

    // Tootip
     var tooltipTriggerList = [].slice.call(document.querySelectorAll('[title]'));
     tooltipTriggerList.map(function(tooltipTriggerEl) {
         return new bootstrap.Tooltip(tooltipTriggerEl);
     });

    // Tratar links vazios
    $('[href=""]').removeAttr('href').attr('role', 'button');

    // Botões de menu
    $('[data-trigger="menu"]').on('click', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        var btn = $(this);
        var target = $(btn.data('target'));
        var expanded = btn.attr('aria-expanded');

        if(expanded === 'true') {
            btn.attr('aria-expanded', 'false');
            target.removeClass('show');
        } else {
            btn.attr('aria-expanded', 'true');
            target.addClass('show');
        }

    });

    // Botões de menu
    $(document).on('click', '[data-trigger="menuSize"]', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        var btn = $(this);
        var target = $(btn.data('target'));
        var size = btn.attr('data-small');

        if(size === 'true') {
            btn.attr('data-small', 'false');
            target.removeClass('small');
        } else {
            btn.attr('data-small', 'true');
            target.addClass('small');
        }

        let data = {
            url: '/menu',
            data: (size !== 'true'),
        };

        console.log(data);

        makeRequest(data);
    });

    // Botão voltar ao perfil original
    $('[data-trigger="userBack"]').on('click', function(e)
    {
        e.preventDefault();
        e.stopPropagation();

        const url = '/user/back';

        const data = {
            url: url,
            data: null
        };

        makeRequest(data);
    });

    // Botões Ajax diversos
    $('[data-trigger="ajax"]').on('click', function(e)
    {
        e.preventDefault();
        e.stopPropagation();

        let btn = $(this);
        let confirm = btn.data('message');
        let id = btn.data('id');

        let url = btn.attr('href');
        if(id) url+= '/'+ id;

        let data = {
            url: url,
        };

        if(confirm) {
            data.message = confirm;
            confirmAlert(data);
        } else {
            makeRequest(data);
        }
    });

    // Botão de excluir
    $(document).on('click', '[data-trigger="delete"]', function(e)
    {
        e.preventDefault();
        e.stopPropagation();

        console.log($(this));

        let btn = $(this);
        let id = btn.data('id');
        let url = btn.data('url');
        let message = btn.data('message');

        let data = {
            method: 'delete',
            url: url,
            data: id,
            message: message
        };

        confirmAlert(data);
    });
})
