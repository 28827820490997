
/**
 * -----------------------------------------------------------------------------
 * Alerta de retorno
 * -----------------------------------------------------------------------------
 *
 * @param {object} data
 */
function sweetAlert(data)
{
    if(1 == 1
        && (typeof data.success === 'undefined')
        && (typeof data.title === 'undefined')
        && (typeof data.message === 'undefined')
        && (typeof data.err === 'undefined')
    ) {
        if(typeof data.redirect !== 'undefined') {
            window.location.href = data.redirect;
        }

        window.location.reload();
        return;
    }

    var fire = {
        icon: (data.success) ? 'success' : 'error',
    }

    if(typeof data.title !== 'undefined') {
        fire.title = data.title;
    }

    if(typeof data.message !== 'undefined') {
        fire.html = data.message;
    }

    if(typeof data.err !== 'undefined') {
        var errors = '<ul>';
        data.err.forEach((item) => {
            errors+= '<li>'+ item +'</li>';
        });

        errors+= '</ul>';

        fire.html = errors;
    }

    Swal.fire(fire).then(() => {

        if(data.success) {
            (typeof data.redirect == 'undefined')
                ? window.location.reload()
                : window.location.href = data.redirect;
        }
    })
}

/**
 * -----------------------------------------------------------------------------
 * Fazer a requisição ajax
 * -----------------------------------------------------------------------------
 *
 * @param {object} obj
 */
const makeRequest = (obj, alert = true) =>
{
    if(!obj.method) obj.method = 'put';

    $.ajax({
        url: obj.url,
        method: obj.method,
        data: obj.data,
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,

        success: (data) => {

            if(alert) {
                sweetAlert(data);
            }
        }
    });
}

/**
 * -----------------------------------------------------------------------------
 * Alerta de confirmação
 * -----------------------------------------------------------------------------
 *
 * @param {object} data
 */
const confirmAlert = (data) =>
{
    Swal.fire({
        icon: 'warning',
        title: data.message,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
    }).then((result) => {

        if(result.isConfirmed) {
            makeRequest(data);
        }

    });
}

module.exports = {
    sweetAlert: sweetAlert,
    makeRequest: makeRequest,
    confirmAlert: confirmAlert,
}
